<script lang="ts" setup>
const { open } = useCartModal()
const { count } = useCart()
const { t } = useI18n()

const ariaLabel = computed(() => {
  const product = t('Product', { count: count.value || 0 })
  return `Shopping cart, ${product}`
})
</script>
<template>
  <HeaderActionButton class="cart hover" :aria-label="ariaLabel" @click="open">
    <Icon name="cart" />
    <Badge v-if="count">{{ count }}</Badge>
  </HeaderActionButton>
  <ShopCartModal />
</template>
<style lang="scss" scoped>
.cart {
  position: relative;
}

.badge {
  position: absolute;
  bottom: 55%;
  left: 50%;
}
</style>
